import React, { useEffect, useState, useReducer, useRef } from 'react';
import { withRouter } from "../providers/withRouter";
import Footer from '../static/footer';
import Menu from '../static/menu';
import { useTranslation } from "react-i18next";
import 'slick-carousel';

import Ciudad from '../images/Contacto/img-marbella.jpg';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { Link} from "react-router-dom";
import useGeoLocation from "react-ipgeolocation";
import { getPropiedad } from '../providers/api';
import sinImagen from './../images/sin-imagen.jpg'

import Maps from '../static/Maps';
import Gallery from '../static/gallery';
import notFound404 from '../static/404'
import { Tb360View } from "react-icons/tb";
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';
import SEO from '../utilities/SEO';
const Propiedad = (props) => {
    let loc_country = useGeoLocation().country;
    loc_country = loc_country === undefined ? '': loc_country.toLowerCase();


    const [propiedad, setPropiedad] = useState({})
    const [propiedades, setPropiedades] = useState({})
    const [show, setShow] = useState(false);
    const [ datos, setDatos ] = useState({})
    const [ componente, setComponente ] = useState(false);
    const [ fotos, setFotos ] = useState([]);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ numIdioma, setNumIdioma ] = useState(0);
    const galleryRef = useRef(null);
    const [no_existe, setNotExist] = useState({});
    const [activeTab, setActiveTab] = useState('');
    const pics = [];
    const [textos, setTextos ] = useState({});
    const { t, i18n } = useTranslation("global");

    const showPhone = (val,coun,ev,form) =>{
        
        const telefono = form
        setDatos({
            ...datos,
            [ev.target.name]: telefono
        });
    }
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }

    const handleChange = (evt) => {
        let value = ''
        if(evt.target.name=== 'terms' || evt.target.name==='suscribir'){
            value = evt.target.checked
        }else{
            value = evt.target.value
        }
        setDatos({
          ...datos,
          [evt.target.name]: value
        });
    };
    const activatePill = async () => {
      
        document.querySelectorAll('#v-pills-tab .nav-link').forEach((link) => link.classList.remove('active'));
        document.querySelectorAll('#v-pills-tabContent .tab-pane').forEach((tab) => {tab.classList.remove('show','active')});

        document.querySelector('#v-pills-gallery-tab').classList.add('active');
        document.querySelector('#v-pills-gallery').classList.add('show','active');
        // const fscreen = document.getElementsByClassName('image-gallery-fullscreen-button');
        // console.log(fscreen[0].onClick()); 
        if(galleryRef.current){
            galleryRef.current.toggleFullScreen();
        }
    };
    const fetchData = async () => {
        let data = await getPropiedad(props.params.ref);
      
        if(data == ""){
            setNotExist({status:404});
        }else{
            setPropiedad(data);
        }
         
    };

    useEffect(()=>{
        let idioma = i18n.language
        let numI = 0
        switch (idioma) {
            case 'es':
                numI=1;
            break;
            case 'en':
                numI=2;
            break;
            case 'fr':
                numI=4;
            break;
            default:
                numI=1;
        }
        setNumIdioma(numI);
        fetchData();

    },[i18n.language]);

    useEffect(() => {
        window.fbq('track', 'ViewContent', {
            content_ids: [props.params.ref],
        });
    }, [props.params.ref])

    useEffect(()=>{
        propiedad?.fotos?.forEach(f => {
            pics.push({original:f.url,thumbnail:f.url});
        });
        setTextos(propiedad?.descrips?.find(txt => txt?.id_idioma === numIdioma));
        setFotos(pics);
    },[propiedad]);

    return ( 
        <>
            {SEO({title: textos?.titulo, description: textos?.descrip, type: 'article', image: fotos?.[0]?.original, ruta: "/propiedades/" + props.params.ref, refPropiedad: props.params.ref, precioPropiedad: propiedad?.precioalq || propiedad?.precioinmo})}
            <Menu/>
            {no_existe.status == 404 ? 
                <></>
                :
            <div className="container-fluid bg-color-gray-dark pt-5 d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        {fotos.length>0 ?  <Gallery images={fotos} galleryRef={galleryRef} gal={0}/>
                        :
                            <img src={sinImagen}/>
                        }
                           
                    </div>
                </div>
            </div>
            }
                 
            <div className="container-fluid p-0">
                {no_existe.status == 404 ? 
                    <div className='text-center my-5 display-6' style={{ fontWeight: 'lighter' }}>
                        <p className='not-found'>404 </p>
                        <p>Propiedad no encontrada</p>
                    </div>
                    :
                    
                <div className="container-fluid bg-color-gray-dark">
                    <div className="container pt-5 wow fadeInUp" data-wow-delay="0.1s">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li className="breadcrumb-item"><Link to={`#`}>{propiedad.provincia}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{`${propiedad.tipo_ofer} en ${propiedad.ciudad}`}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="container pb-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="prop-dir" >
                            <div className="">
                                    <h3>{propiedad.nombre}</h3>
                                    <p className='mb-0'><i className="fa fa-location-dot me-2"></i><span className='color-primary me-2'>{propiedad.provincia}</span>{propiedad.direccion}</p>
                            </div>
                            <div className="">
                                <h3>
                                    <strong>
                                        
                                        {propiedad.accion === "Alquilar" || propiedad.accion == "Alquiler con Opcion a Compra" || propiedad.accion == "Temporada Alquiler"
                                            ? `${propiedad?.precioalq?.toLocaleString('de-DE')} ${t('propiedad.caracteristicas.alqpormes')}`
                                            : (propiedad?.precioinmo === 0 || propiedad?.precioinmo === undefined || propiedad?.precioinmo === null)
                                                ? `${t('propiedad.caracteristicas.aconsultar')}`
                                                : `${propiedad?.precioinmo?.toLocaleString('de-DE')}€`
                                        }
                                    </strong>
                                </h3>
                                <div className='property-info'>
                                    <div><i className='fa fa-bed me-1'></i><span className='me-2'>{propiedad.habitaciones + propiedad.habdobles}</span></div>
                                    <div><i className="fa fa-shower me-1"></i><span className='me-2'>{propiedad.banyos}</span></div>
                                    {propiedad.num_parking > 0 ? <div><i className='fa fa-car me-1'></i><span className='me-2'>{propiedad.plaza_gara}</span></div> : null }
                                    <div><i className='fa fa-ruler-combined me-1'></i><span>{propiedad.m_cons}m<sup>2</sup></span></div>
                                </div>
                                <p style={{ textAlign: 'right'}}><small>ref: {propiedad.referencia}</small></p>
                            </div>
                        </div>
                    </div>
                    <div className="container py-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="row mt-20 p-0">
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-5 px-3">
                                <div className="d-flex align-items-start">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {propiedad?.descrips && propiedad.descrips.length > 0 && (
                                            propiedad.descrips.some(descrip => descrip.titulo !== "" && descrip.descrip !== "")
                                        ) ? (
                                            <button
                                                className="nav-link active"
                                                id="v-pills-description-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-description"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-description"
                                                aria-selected="true"
                                            >
                                                <i className="bi bi-file-earmark-text"></i>
                                            </button>
                                        
                                        ) : null}
                                        <button
                                            className={`nav-link ${activeTab === 'v-pills-caracteristics-tab' || 
                                            !(
                                                propiedad?.descrips &&
                                                propiedad.descrips.length > 0 &&
                                                propiedad.descrips.some(descrip => descrip.titulo !== "" && descrip.descrip !== "")
                                            ) ? 'active' : ''}`}
                                            id="v-pills-caracteristics-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#v-pills-caracteristics"
                                            type="button"
                                            role="tab"
                                            aria-controls="v-pills-caracteristics"
                                            aria-selected={activeTab === 'v-pills-caracteristics-tab' || 
                                            !(
                                                propiedad?.descrips &&
                                                propiedad.descrips.length > 0 &&
                                                propiedad.descrips.some(descrip => descrip.titulo !== "" && descrip.descrip !== "")
                                            )}
                                        >
                                            <i className="bi bi-house-door"></i>
                                        </button>

                                
                                        
                                
                                        {/* <button className="nav-link active" id="v-pills-description-tab" data-bs-toggle="pill" data-bs-target="#v-pills-description" type="button" role="tab" aria-controls="v-pills-description" aria-selected="true"><i className="bi bi-file-earmark-text"></i></button>
                                        <button className="nav-link" id="v-pills-caracteristics-tab" data-bs-toggle="pill" data-bs-target="#v-pills-caracteristics" type="button" role="tab" aria-controls="v-pills-caracteristics" aria-selected="false"><i className="bi bi-house-door"></i></button> */}
                                        <button className={`nav-link ${activeTab === 'v-pills-gallery-tab' ? 'active' : ''}`} id="v-pills-gallery-tab" data-bs-toggle="pill" data-bs-target="#v-pills-gallery" type="button" role="tab" aria-controls="v-pills-gallery" aria-selected="false"><i className="bi bi-images"></i></button>
                                        <button className="nav-link" id="v-pills-location-tab" data-bs-toggle="pill" data-bs-target="#v-pills-location" type="button" role="tab" aria-controls="v-pills-location" aria-selected="false"><i className="bi bi-geo-alt"></i></button>
                                        {propiedad.plano > 0 ? <button className="nav-link" id="v-pills-plan-tab" data-bs-toggle="pill" data-bs-target="#v-pills-plan" type="button" role="tab" aria-controls="v-pills-plan" aria-selected="false"><i className="bi bi-map"></i></button> : null }
                                        {propiedad.tour ? <button className="nav-link" id="v-pills-tour-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tour" type="button" role="tab" aria-controls="v-pills-tour" aria-selected="false"><Tb360View /></button> : null }
                                        {propiedad.video_url ? <button className="nav-link" id="v-pills-video-tab" data-bs-toggle="pill" data-bs-target="#v-pills-video" type="button" role="tab" aria-controls="v-pills-video" aria-selected="false"><i className='bi bi-camera-video'/></button> : null }
                                    </div>
                                    <div className="tab-content w-100" id="v-pills-tabContent">
                                    {propiedad?.descrips && propiedad.descrips.length > 0 && (
                                            propiedad.descrips.some(descrip => descrip.titulo !== "" && descrip.descrip !== "")
                                        ) ? (
                                            <div className="tab-pane fade show active" id="v-pills-description" role="tabpanel" aria-labelledby="v-pills-description-tab">
                                                <h3 className="lead">{textos?.titulo}</h3>
                                                <hr/>
                                                <p>{textos?.descrip?.replace(/[\^*~'\\]/gi, '\n').split('\n')}</p>
                        
                                            </div>
                                            ) : null}
                                     
                                        <div className="tab-pane fade" id="v-pills-caracteristics" role="tabpanel" aria-labelledby="v-pills-caracteristics-tab">
                                            <h3>{t("propiedad.carateristicas")}</h3>
                                        
                                            <hr />
                                            <div className='row g-3'>
                                                <div className='col-lg-6 col-md'>
                                                    <h5>{t("propiedad.basicas")}</h5>
                                                    <ul className="list-none ps-2" style={{ fontSize:"14px" }}>
                                                        <li>
                                                            <i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.construidos')}: </b>{propiedad.m_cons} m<sup>2</sup>
                                                        </li>
                                                        <li>
                                                        {propiedad.m_utiles > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.terraza')}: </b>{propiedad.m_utiles} m<sup>2</sup></li> : <></>}
                                                        </li>
                                                        {propiedad.m_parcela > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.parcela')}: </b>{propiedad.m_parcela} m<sup>2</sup></li> : <></>}
                                                        {propiedad.m_terraza > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.terraza')}: </b>{propiedad.m_terraza} m<sup>2</sup></li> : <></>}
                                                        {propiedad.terraza > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.terrazas')}: </b>{propiedad.terraza}</li> : null}
                                                        {propiedad.piscinaComunitaria > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.piscinaComunitaria')}:  {t('respuestas.si')}</b></li> : null}
                                                        {propiedad.piscinaPropia > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.piscinaPropia')}: </b>  {t('respuestas.si')}</li> : null}
                                                        {propiedad.calefaccioncentral > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.calefaccionCentral')}:   {t('respuestas.si')}</b></li> : null}
                                                        {propiedad.calefaccion > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.calefaccion')}:  {t('respuestas.si')}</b></li> : null}
                                                        {propiedad.energialetra !== undefined ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.energiaLetra')}:</b>  {propiedad.energialetra}</li> : null}
                                                        <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.habitaciones')}: </b> {propiedad.habitaciones + propiedad.habdobles}</li>
                                                        <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.baños')}: </b> {propiedad.banyos}</li>
                                                        {propiedad.balcon > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.balcon')}: </b> {propiedad.balcon}</li> : null}
                                                        {propiedad.aseos > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.aseos')}: </b> {propiedad.aseos}</li> : null}
                                                        {propiedad.urbanizacion > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.urbanizacion')}: </b> {t('respuestas.si')}</li> : null}
                                                        {propiedad.gimnasio > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.gimnasio')}: </b> {t('respuestas.si')} </li> : null}
                                                        {propiedad.solarium > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.solarium')}: </b> {t('respuestas.si')}</li> : null}
                                                        
                                                    </ul>
                                                </div>
                                                <div className='col-lg-6 col-md'>
                                                    <h5>{t("propiedad.edificio")}</h5>
                                                    <ul className="list-none ps-2" style={{ fontSize:"14px" }}>
                                                        {propiedad.orientacion !== undefined && propiedad.orientacion !== ''&& propiedad.orientacion !== null ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.orientacion')}: </b>{propiedad.orientacion}</li>:<></>}
                                                        {propiedad.conservacion!== undefined ?<li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.estado')}: </b>{propiedad.conservacion}</li>:<></>}
                                                        {propiedad.antiguedad !== undefined && propiedad.antiguedad !==  null && propiedad.antiguedad !== 0?<li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.año')}: </b>{propiedad.antiguedad}</li>: <></>}
                                                        <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.ascensor')}: </b>{propiedad.ascensor > 0 ? t('propiedad.caracteristicas.ascensorSi'): t('propiedad.caracteristicas.ascensorNo')}</li>
                                                        {propiedad.numPlanta > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.planta')}: </b>  {propiedad.numPlanta} </li>:<></> }
                                                        {propiedad.primeraLinea === 1 ? <li><i className="bi bi-dot color-primary me-2"></i> <b>{t('ropiedad.caracteristicas.primeraLinea')}: </b> {t('respuestas.si')}</li> : null }
                                                        {propiedad.plaza_gara > 0 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.plazaGaraje')}: </b>{propiedad.nplazasparking}</li> : null }
                                                        {propiedad.vistasMar === 1 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.VistasMar')}: </b> {t('respuestas.si')}</li> : null }
                                                        {propiedad.vistasDespejadas === 1 ? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.VistasDespejadas')}</b>  {t('respuestas.si')}</li> : null }
                                                        {propiedad.distmar !== undefined || parseInt(propiedad.distmar) > 0? <li><i className="bi bi-dot color-primary me-2"></i><b>{t('propiedad.caracteristicas.DistanciaMar')}: </b>{propiedad.distmar}m</li> : <></> }
                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 'v-pills-gallery' ? 'show active' : ''} `} id="v-pills-gallery" role="tabpanel" aria-labelledby="v-pills-gallery-tab" >
                                            {fotos.length>0 ?  
                                                <Gallery images={fotos} galleryRef={galleryRef} gal={1}/>
                                            :
                                                <img src={sinImagen} width={"700hv"}/>
                                            }
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-location" role="tabpanel" aria-labelledby="v-pills-location-tab">
                                            <Maps coordenadas={[{lat: propiedad?.latitud, lng: propiedad.longitud}]} />
                                        </div>
                                        {propiedad.plano > 0 ? 
                                            <div className="tab-pane fade" id="v-pills-plan" role="tabpanel" aria-labelledby="v-pills-plan-tab">
                                                <h3>{t("propiedad.plano")}</h3>
                                                <hr/>
                                                <img className="img-fluid" onClick={toggleOpen} src={propiedad.imgPlano.foto} alt={propiedad.imgPlano.etiqueta}/>
                                            </div> 
                                        : null}
                                        {propiedad.tour ? 
                                            <div className="tab-pane fade" id="v-pills-tour" role="tabpanel" aria-labelledby="v-pills-tour-tab">
                                                <iframe src={propiedad.tour} width={'100%'} height={'450px'}/>
                                               
                                            </div> 
                                        : null}
                                        {propiedad.video_url ? 
                                            <div className="tab-pane fade" id="v-pills-video" role="tabpanel" aria-labelledby="v-pills-video-tab">
                                                <ReactPlayer 
                                                    url={propiedad.video_url} 
                                                    width={'100%'} 
                                                    height={'450px'}
                                                    controls={true}
                                                />
                                               
                                            </div> 
                                        : null}
                                    </div>
                                </div>
                    
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-5 px-3">
                                <div className='p-4 bg-white'>
                                    <h4 className='color-primary'>{t('propiedad.informacionContacto')}</h4>
                                    <Link className="btn w-100 text-left" to={`mailto:${propiedad?.sucursal?.email}`} alt={`${propiedad?.sucursal?.email}`}>
                                        <i className="fa fa-envelope"></i> {propiedad?.sucursal?.email}
                                    </Link>
                                    <Link className="btn w-100 text-left" to={`tel:${propiedad?.sucursal?.telefono}`} alt={`${propiedad?.sucursal?.telefono}`}>
                                        <i className="fa fa-phone"></i>{propiedad?.sucursal?.telefono}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            <Footer />
            <style jsx="true">{`
                .parallax{
                    background-image: url(${`'${Ciudad}'`});
                    min-height: 500px;
                    background-attachment: fixed;
                    background-position: center;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    background-position-x: right;
                    margin: 0;
                }
                .bg-imagen{
                    background-image: url(${`'${propiedad.imagen}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .react-tel-input .flag-dropdown {
                    background-color:white !important;
                }
                .react-tel-input .form-control, .react-tel-input .flag-dropdown {
                    border-bottom: none !important;
                }
                .react-tel-input{
                    border-bottom: 1px solid #dee2e6 !important;
                }
                .react-tel-input .flag-dropdown {
                    background-color:white !important;
                }
                .react-tel-input .form-control, .react-tel-input .flag-dropdown {
                    border-bottom: none !important;
                }
                .react-tel-input{
                    border-bottom: 1px solid #dee2e6 !important;
                }
            `}</style>
        </>
    );
}
 
export default withRouter(Propiedad);