import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Simbolo from './../images/favicon_laehomes.svg'
const ResultadoForm = ({ datosFiltrados }) => {
    const { t, i18n } = useTranslation("global");
    const location = useLocation();
    const [ numIdioma, setNumIdioma ] = useState(0);
    let idioma = i18n.language;
    useEffect(()=>{
        let numI = 0
        switch (idioma) {
            case 'es':
                numI=1;
            break;
            case 'en':
                numI=2;
            break;
            case 'fr':
                numI=4;
            break;
            default:
                numI=1;
        }
        setNumIdioma(numI);
    },[]);
        return (
            <>
                <div className="container-xxl pt-5">
                    <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <div className="row g-4">
        
                            {datosFiltrados.length === 0 || datosFiltrados == '' ?
                                <div className='text-center my-5 display-6' style={{ fontWeight: 'lighter' }}>{t("nopropiedades")}</div> :
                                    datosFiltrados.map((p,i) => {
                                    return(
                                        <div key={i} className="col-lg-4 col-md-6">
                                            <div className="service-item position-relative h-100">
                                                <Link to={`/propiedades/${p.referencia}`} target={'_blank'}>
                                                    <div className="service-text">
                                                        <div className="property-img mx-auto">
                                                            {/* <div className='etiqueta'><span>COMPRA</span></div> */}
                                                            <img className="img-fluid" src={p?.foto?.url} alt="Imagen" />  
                                                        </div>
                                                        <div className='p-5 property-details-container'>
                                                            <div className='p-3 property-details' >
                                                                {/* <Link to={`/obra-nueva/${p.cod_ofer}`} className="btn btn-primary rounded-circle" style={{ position: 'absolute', top: '-20px', right: '20px'}}><i className='fa fa-plus'></i></Link> */}
                                                                <h5 className="mb-3">{`${p.tipo_ofer} en ${p.ciudad}`}</h5>
                                                                <h6 className="mb-3">{p.zona}</h6>
                                                                <p className="mb-2 text-price">
                                                                    {
                                                                        p.accion === "Alquilar" || p.accion == "Alquiler con Opcion a Compra" || p.accion == "Temporada Alquiler"
                                                                        ? `${Number(p?.precioalq)?.toLocaleString('de-DE')} ${t('propiedad.caracteristicas.alqpormes')}`
                                                                        : (p?.precioinmo === 0 || p?.precioinmo === undefined || p?.precioinmo === null)
                                                                            ? `${t('propiedad.caracteristicas.aconsultar')}`
                                                                            : `${Number(p?.precioinmo)?.toLocaleString('de-DE')}€`
                                                                    }
                                                                </p>
                                                                <p className="mb-2 color-primary"><b>{p.provincia}</b></p>
                                                                <div className='property-info'>
                                                                    <div><i className='fa fa-bed me-1'></i>{p.habitaciones + p.habdobles}</div>
                                                                    <div><i className="fa fa-shower me-1"></i>{p.banyos}</div>
                                                                    <div><i className='fa fa-car me-1'></i>{p.nplazasparking}</div>
                                                                    <div><i className='fa fa-ruler-combined me-1'></i>{p.m_cons}m<sup>2</sup></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {/* <div className="p-3">
                                {datosFiltrados.length > 0 && 
                                    <div className='text-center'>
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={changePage}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
            );       
    // }

};

export default ResultadoForm;